html, body {
    height: 100%;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.App {
    flex: 1 1 auto;
    height: 100%;
}

.brand-xl {
    font-weight: 500;
}

@media (min-width: 640px) {
    
}

@media (min-width: 1024px) {
    .brand-xl {
        font-weight: 500;
        -webkit-text-stroke: 1px #666;
        text-shadow: 2px 2px #000;
    } 
}